<template>
  <div class="main">
    <newhead />
    <div class="ny-con">
      <grzxnav />
      <div class="grzx-con">
        <el-divider content-position="left">账户信息</el-divider>
        <div class="grzx-con-st">
          <div class="grzx-con-st-list">
            <div class="grzx-con-st-list-title">当前积分：</div>
            <div class="grzx-con-st-list-txt">{{ userinfoinxi.balance }}</div>
          </div>
        </div>
        <el-tabs
          v-model="activeName"
          type="card"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="充值记录" name="chongzhi">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="realname" label="姓名" width="150" />
              <el-table-column label="充值类型" width="150">
                <template #default="scope">
                  <el-tag v-if="scope.row.otype == 1" type="primary">微信充值</el-tag>
                  <el-tag v-if="scope.row.otype == 2" type="success">注册赠送</el-tag>
                  <el-tag v-if="scope.row.otype == 3" type="warning">合同订单</el-tag>
                  <el-tag v-if="scope.row.otype == 5" type="success">余额支付</el-tag>
                  <el-tag v-if="scope.row.otype == 7" type="danger">手动充值</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="充值数（积分）">
                <template #default="scope">
                  <span>{{scope.row.moneyyuan}}</span>
                </template>
              </el-table-column>
              <el-table-column label="支付状态">
                <template #default="scope">
                  <span v-if="scope.row.pstatus==1" style="color:#666">未支付</span>
                  <span v-if="scope.row.pstatus==2" style="color:#409eff">支付成功</span>
                  <span v-if="scope.row.pstatus==3" style="color:red">支付失败</span>
                </template>
              </el-table-column>
              <el-table-column prop="ctime" label="操作时间" width="200" />
            </el-table>
            <div class="tablesfy-fy">
              <el-pagination
                background
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
                @size-change="handleSizeChange"
                @current-change="fanye"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="消费记录" name="xiaofei">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="realname" label="姓名" width="150" />
              <el-table-column label="消费类型" width="150">
                <template #default="scope">
                  <el-tag v-if="scope.row.otype == 1" type="primary">微信支付</el-tag>
                  <el-tag v-if="scope.row.otype == 4" type="success">余额支付</el-tag>
                  <el-tag v-if="scope.row.otype == 5" type="success">余额支付</el-tag>
                  <el-tag v-if="scope.row.otype == 6" type="success">余额支付</el-tag>
                  <el-tag v-if="scope.row.otype == 7" type="warning">系统操作</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="消费数（积分）">
                <template #default="scope">
                  <span>{{scope.row.moneyyuan}}</span>
                </template>
              </el-table-column>
              <el-table-column label="支付状态">
                <template #default="scope">
                  <span v-if="scope.row.pstatus==1" style="color:#666">未支付</span>
                  <span v-if="scope.row.pstatus==2" style="color:#409eff">支付成功</span>
                  <span v-if="scope.row.pstatus==3" style="color:red">支付失败</span>
                </template>
              </el-table-column>
              <el-table-column prop="ctime" label="操作时间" width="200" />
            </el-table>
            <div class="tablesfy-fy">
              <el-pagination
                background
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[10, 20, 50, 100]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
                @size-change="handleSizeChange"
                @current-change="fanye"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from "@element-plus/icons-vue";

const router = useRouter();
// tab初始项
const activeName = ref("chongzhi");
//关键词
let keywords=ref('')
// 类型  1充值  2消费
let lx=ref(1)
// 表格数据
const tableData = reactive([]);
// 分页先关
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(10);

let userinfoinxi = reactive({});
// 获取用户信息
const getuserinfo = () => {
  get("/api/User/GetTheUserInfo").then((result) => {
    Object.assign(userinfoinxi, result.data);
  });
};
getuserinfo();



// 获取列表
const getlist = (pg) => {
  tableData.length = 0;
  post("/api/User/GetUserPayList", {
    key: keywords.value,
    lx: lx.value,
    page: pg,
    psize: pageSize.value,
  })
    .then((res) => {
      totals.value = res.total;
      tableData.push(...res.data);
      currentPage.value = pg;
    })
    .catch((err) => {
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};
getlist(1);
// 分页
const fanye = (val) => {
  page.value = val;
  getlist(val);
};

const handleSizeChange = (val) => {
  pageSize.value = val;
  getlist(1);
};

// 点击tab
const handleClick = (tab, event) => {
  switch (tab.props.name) {
    case "xiaofei":
      // 消费列表
      lx.value=2
      getlist(1);
      break;
    case "chongzhi":
      // 充值列表
      lx.value=1
      getlist(1);
      break;
    default:
    // 充值列表
    lx.value=1
    getlist(1);
  }
};





</script>

<style scoped>
:deep(.el-menu) {
  border-right: 0;
}
:deep(.el-divider__text) {
  font-weight: bold;
  font-size: 18px;
}
.main {
  background: #ebedf3;
}
.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}
.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1600px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
  margin-top: 50px;
  
}
.ny-con:after {
  content: "";
  display: table;
  clear: both;
}
.grzx-nav {
  float: left;
  width: 240px;
  min-height: 600px;
  padding-right: 30px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.grzx-con {
  float: right;
  width: calc(100% - 240px);
  height: auto;
  box-sizing: border-box;
  padding-left: 30px;
}
.grzx-con span {
  font-weight: bold;
}

.grzx-nav-tx {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.grzx-nav-yhm {
  width: calc(100% - 20px);
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.grzx-nav-st {
  padding: 20px 0;
}
.grzx-nav-yhm-lever {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 5px 0;
  background: url("/img/gerenzhongxin/VIP.png");
}
.grzx-nav-yhm-lever-num {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #fff;
  font-weight: bold;
}
.grzx-con-st {
  padding: 10px 0;
}
.grzx-con-st-list {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.grzx-con-st-list-title {
  width: 100px;
}
.grzx-con-st-list-txt {
  width: calc(100% - 100px);
}
.gezx-nr-cz {
  margin-top: 20px;
}
.tablesfy-fy {
  margin-top: 10px;
}
</style>
